body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgb(22, 54, 58);
}

.logo {
  max-width: 500px;
  height: auto;
  margin-bottom: 0px;
}

.fade-in-text {
  font-family: 'Anonymous Pro', monospace;
  font-size: 2rem;
  color: rgb(228, 244, 228);
  animation: fadeIn 2s ease-in-out;
}

h2 {
  font-size: 1rem;
  color: rgb(228, 244, 228);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
